import clsx from 'clsx';
import PriceModifier from 'components/PriceModifier';
import Spinner from 'components/Spinner';
import { VisibilityModel } from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import React, { useState } from 'react';
import { localStorageService } from 'services/localStorageService';
import { useGetProductLastPricesMutation } from 'store/reducers/orders/ordersSliceApi';
import { isString } from 'utils/type-guards';

import { useTypedOrderControllerFromContext } from '../OrderController';
import { priceTypeColorMap } from './config';
import styles from './styles.module.css';
import type { IProps } from './types';

const buttons = [
	{
		label: 'Вхід.',
		id: 'enterPrice',
		isLongRequest: false,
	},
	{
		label: 'Мін.',
		id: 'minimalPrice',
		isLongRequest: false,
	},
	{
		label: 'Опт.',
		id: 'wholesalePrice',
		isLongRequest: false,
	},
	{
		label: 'Ост ц.',
		id: 'lastPrice',
		isLongRequest: true,
	},
];

const PriceTypes: React.FC<IProps> = ({
	children,
	className,
	onVisibilityModelChange,
	disableAll = false,
	justify = 'space-between',
	hydrateStateKey,
	suborderIndex,
}) => {
	const [activeIds, setActiveIds] = useState<string[]>(hydrateState(hydrateStateKey)());
	const dialogue = useOrderAlertDialogue();
	const { getValues } = useTypedOrderControllerFromContext();

	const suborder = getValues('suborders')?.[suborderIndex];
	const client = suborder?.data?.client;
	const contract = suborder?.data?.contract;

	const [fetchData, { reset, isLoading }] = useGetProductLastPricesMutation({
		fixedCacheKey: `${client?.value}:${contract?.value}`,
	});

	const types = buttons.map(({ label, id, isLongRequest }, index) => ({
		...priceTypeColorMap[index],
		modifierDisplayValue: label,
		id,
		isLongRequest,
	}));

	const toggleColumns = (id: string) => {
		setActiveIds((prev) => (prev.includes(id) ? prev.filter((activeId) => activeId !== id) : [...prev, id]));

		onVisibilityModelChange((prevModel) => ({ ...prevModel, [id]: !prevModel?.[id] }));
	};

	const handleSelectionSafe = (id: string, isLongRequest: boolean) => {
		if (disableAll) return;

		if (isLongRequest) {
			dialogue.open('showLastPriceColumn', {
				onSubmit: async () => {
					try {
						await fetchData({ clientId: client?.value, contractId: contract?.value }).unwrap();
						toggleColumns(id);
					} catch {
						dialogue.open('noLastPriceFound', {
							data: client.label,
							onSubmit: () => {
								reset();
								dialogue.close();
							},
							onCancel: () => {
								reset();
								dialogue.close();
							},
						});
					}
				},
			});
		} else {
			toggleColumns(id);
		}
	};

	return (
		<div className={clsx(styles.modifiersWrapper, className)} style={{ justifyContent: justify }}>
			{children}

			<ul className={styles.list}>
				{types.map((item) => {
					const isActive = activeIds.includes(item.id);

					return (
						<li key={item.id}>
							<PriceModifier
								isActive={isActive}
								modifierDisplayValue={item.modifierDisplayValue}
								activeColor={item.activeColor}
								bgColor={item.bgColor}
								borderColor={item.borderColor}
								fontColor={item.fontColor}
								dotColor={item.dotColor}
								hoverBgColor={item.hoverBgColor}
								onClick={(e) => {
									e.stopPropagation();
									handleSelectionSafe(item.id, item.isLongRequest);
								}}
								disabled={disableAll}
							/>
						</li>
					);
				})}
			</ul>

			{isLoading && <Spinner />}
		</div>
	);
};

export default PriceTypes;

function hydrateState(key: string) {
	return function hydrate(): string[] {
		const visibilityModel = localStorageService.load<VisibilityModel>(key);

		if (visibilityModel) {
			const internalStateModel = Object.entries(visibilityModel)
				.filter(([, isVisible]) => isVisible)
				.map(([id]) => id)
				.filter(isString);

			return internalStateModel;
		}

		return [];
	};
}
