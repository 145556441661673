import { useCallback, useState } from 'react';

export const useBoolean = () => {
	const [isOn, setIsOn] = useState<boolean>(false);

	const toggle = useCallback(() => setIsOn((prevState) => !prevState), []);
	const close = useCallback(() => setIsOn(false), []);
	const open = useCallback(() => setIsOn(true), []);

	return {
		isOn,
		toggle,
		close,
		open,
	};
};
