import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';

const productSuccessfullyAdded = () => toast.success('Товар додано до заявки');

export const withOneStepOrderCart = <TProps extends object>(Component: FunctionComponent<TProps>, { mode }: { mode: 'creation' | 'edit' }) => {
	const WrappedComponent = (props: Omit<TProps, 'mode' | 'onSave' | 'onRowClick' | 'isLoading'>) => {
		const cart = useOrderCart();
		const { addEntityToOrder } = useOrderOperationMethods();

		const onRowClick = async (rowIndex: number, quantity: number | string) => {
			if (rowIndex === 0) {
				cart.updateProductQuantityInCart(quantity);
			} else {
				const searchParams = new URLSearchParams(window.location.search);
				const product = cart.getPreviewProduct();
				const addTo = Number(searchParams.get('from') || 0);
				addEntityToOrder({ candidates: [{ ...product, amount: String(quantity) }], entityName: 'products', to: addTo });

				productSuccessfullyAdded();
				cart.closeModal();
				cart.clearMaybeSelectOne();
			}
		};

		return (
			<Component
				{...(props as TProps)}
				onRowClick={onRowClick}
				onSave={null}
				isLoading={cart.isLoading}
				mode={mode}
				onAddEntitySuccess={productSuccessfullyAdded}
			/>
		);
	};

	return WrappedComponent;
};
