import clsx from 'clsx';
import AutocompleteServerInput from 'components/AutocompleteServerInput ';
import { AutocompleteFieldWithOnSelectAction } from 'components/FormComponents/Inputs/inputs';
import { AnyOption, Option, OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useGetClientsOptionsQuery, useGetContractListQuery } from 'store/reducers/clients/clientsSliceApi';
import { useGetEmployeesOptionListQuery, useGetOrganizationsOptionListQuery, useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

import { Can } from '../OrderAbility/provider';
import { useTypedOrderControllerFromContext } from '../OrderController';
import styles from './styles.module.css';

const fallbackList = [];

interface OrderInternalInfoModuleProps {
	suborderIndex: number;
}

const useGetClients = (queryArg: string | string[]) => {
	const query = Array.isArray(queryArg) ? queryArg[0] : queryArg || '';
	const { data, isFetching, isLoading } = useGetClientsOptionsQuery(query);

	return {
		data,
		isFetching,
		isLoading,
	};
};

const OrderInternalDataControlModule: React.FC<OrderInternalInfoModuleProps> = ({ suborderIndex }) => {
	const { setValue, formState, control } = useTypedOrderControllerFromContext();
	const client = useWatch({ name: `suborders.${suborderIndex}.data.client`, control });

	const { data: stocksOptionList = fallbackList, ...stocksRequest } = useGetWarehousesQuery(undefined);
	const { data: managersOptionList = fallbackList, ...managersRequest } = useGetEmployeesOptionListQuery();
	const { data: organizationsOptionList = fallbackList, ...organizationsRequest } = useGetOrganizationsOptionListQuery();
	const { data: rawContractsOptionList = fallbackList, ...contractsRequest } = useGetContractListQuery(client?.value, { skip: !client?.value });

	const contractsOptionList = useMemo(() => {
		const schema = standardizeOption<typeof OptionSchema, AnyOption>(OptionSchema, ({ id, title }) => ({ label: title, value: id }));

		const options = rawContractsOptionList.map((contract) => schema.safeParse(contract).data) as Option[];

		return options;
	}, [rawContractsOptionList, suborderIndex]);

	useEffect(() => {
		if (!client) {
			setValue(`suborders.${suborderIndex}.data.contract`, null);
		}
	}, [client]);

	useEffect(() => {
		if (contractsOptionList) {
			const [defaultContract = null] = contractsOptionList;

			setValue(`suborders.${suborderIndex}.data.contract`, defaultContract);
		}
	}, [contractsOptionList]);

	const getFieldError = (fieldName: string) => formState.errors?.suborders?.[suborderIndex]?.data?.[fieldName]?.message;

	return (
		<form className={styles.form} style={{ '--columns': 5 } as React.CSSProperties}>
			<Can passThrough I="change" an={`order.${suborderIndex}.client`}>
				{(can) => (
					<Controller
						name={`suborders.${suborderIndex}.data.client`}
						control={control}
						render={({ field }) => {
							return (
								<AutocompleteServerInput
									name={field.name}
									label="Клієнт"
									ref={field.ref}
									placeholder="Виберіть клієнта"
									initialValue={field.value as Option}
									onSelect={(value) => field.onChange(value)}
									useLoadAsyncDataQuery={useGetClients}
									className={clsx({ [styles.error]: !!getFieldError('client') })}
									disabled={!can}
								/>
							);
						}}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.organization`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.organization`}
						label="Організація"
						placeholder="Виберіть організацію"
						valuesList={organizationsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('organization') })}
						disabled={!can || organizationsRequest.isLoading}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.contract`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.contract`}
						label="Договір"
						placeholder="Виберіть договір"
						valuesList={contractsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('contract') })}
						disabled={!can || contractsRequest.isLoading || contractsRequest.isFetching}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.stock`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.stock`}
						label="Склад"
						placeholder="Виберіть склад"
						valuesList={stocksOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('stock') })}
						disabled={!can || stocksRequest.isLoading}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.responsible`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.responsible`}
						label="Менеджер"
						placeholder="Виберіть менеджера"
						valuesList={managersOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('responsible') })}
						disabled={!can || managersRequest.isLoading}
					/>
				)}
			</Can>
		</form>
	);
};

export default OrderInternalDataControlModule;
