import clsx from 'clsx';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetClientsQuery } from 'store/reducers/clients/clientsSliceApi';
import { isObject } from 'utils/type-guards';

import { useMainSearchStateAdapter } from './hooks/useMainSearchStateAdapter';
import type { MainSearchDataItem, MainSearchProps, UseMainSearchQuery } from './lib/types';
import { MainSearchContext, MainSearchProvider } from './Provider';
import styles from './styles.module.css';

const defaultTransform = (item: unknown): MainSearchDataItem => {
	if (isObject(item) && 'id' in item) {
		return { id: String(item?.id), title: String(item['organizationName'] || item['name']) };
	}

	return { id: String(performance.now()), title: 'Default item' };
};

const MainSearch = <TUseQuery extends UseMainSearchQuery>({
	queryKey,
	useLoadAsyncDataQuery = useGetClientsQuery as TUseQuery,
	transformQueryResult = defaultTransform,
	children,
	sharable = false,
}: MainSearchProps<TUseQuery>) => {
	const { query, initialQuery, onChange } = useMainSearchStateAdapter({ queryKey, sharable });
	const { data, isLoading, isFetching } = useLoadAsyncDataQuery([query, queryKey]);
	const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

	const closeDropdown = useCallback(() => setIsDropdownOpened(false), []);
	const openDropdown = useCallback(() => {
		setIsDropdownOpened(true);
	}, []);

	const ref = useClickOutside<HTMLDivElement>(closeDropdown);

	const onOptionClick = useCallback(() => {
		closeDropdown();
	}, []);

	const context: MainSearchContext = useMemo(
		() => ({
			query,
			queryKey,
			initialQuery,
			items: data?.data?.map(transformQueryResult) ?? [],
			onChange,
			onOptionClick,
			openDropdown,
			closeDropdown,
			isDropdownOpened,
			isLoading: isLoading || isFetching,
		}),
		[data, onChange, isDropdownOpened, onOptionClick, isLoading, isFetching, query, queryKey],
	);

	return (
		<MainSearchProvider value={context}>
			<div ref={ref} className={styles.mainSearch}>
				<div className={clsx(styles.inputArea, styles.ring)}>{children}</div>
			</div>
		</MainSearchProvider>
	);
};

export default MainSearch;
