import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import cssStyles from './styles.module.css';

interface CommentsTooltipProps {
	children: React.ReactNode;
	text: string;
}

const CommentsTooltip: React.FC<CommentsTooltipProps> = ({ children, text }) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' });
	const [isVisible, setIsVisible] = useState(false);

	return (
		<span
			ref={setReferenceElement}
			onMouseEnter={() => setIsVisible(true)}
			onMouseLeave={() => setIsVisible(false)}
			className={cssStyles.wrapper}
		>
			{children}

			{isVisible &&
				createPortal(
					<div ref={setPopperElement} style={styles.popper} {...attributes.popper} className={cssStyles.tooltip}>
						{text}
					</div>,
					document.querySelector('#portal'),
				)}
		</span>
	);
};

export default CommentsTooltip;
