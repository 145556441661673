import { useRegularServicesColumns } from 'columns/services';
import EmptyServicesGate from 'components/OrderPageComponents/(EmptyStateGates)/EmptyServicesGate';
import Table from 'components/Table';
import { OnRowSelectionModelChange, RowSelectionModel } from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import ServiceSelectors from 'pages/OrderRework/components/ServiceSelectors';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import { OnEntityDeletePayload, useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import { Can } from 'pages/OrderRework/OrderAbility/provider';
import { ServiceInternalModelState } from 'pages/OrderRework/OrderController';
import { getOrderControllerEntityRowId } from 'pages/OrderRework/OrderController/lib/transform';
import React from 'react';

import styles from './styles.module.css';

interface ServicesProps {
	suborderIndex: number;
	selectionModel: RowSelectionModel;
	onRowSelectionModelChange: OnRowSelectionModelChange;
	triggerRerenderOnAddEntity?: boolean;
	getCanSelectRow: (service: ServiceInternalModelState) => boolean;
}

export const Services: React.FC<ServicesProps> = ({
	suborderIndex,
	selectionModel,
	onRowSelectionModelChange,
	triggerRerenderOnAddEntity = false,
	getCanSelectRow,
}) => {
	const { watch, onEntityDelete, addEntityToOrder } = useOrderOperationMethods();

	const servicesRecord = watch(`suborders.${suborderIndex}.data.services`);
	const services = Object.values(servicesRecord ?? {});

	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: '/preview/order/services/' + suborderIndex });
	const notify = useOrderNotifications();
	const dialogue = useOrderAlertDialogue();

	const handleOneServiceDeleteSafe = (payload: OnEntityDeletePayload) => {
		dialogue.open('deleteServices', { onSubmit: () => onEntityDelete(payload), data: Object.keys(selectionModel ?? {}).length });
	};
	const columns = useRegularServicesColumns({ suborderIndex, onEntityDelete: handleOneServiceDeleteSafe });

	const onAddService = (service: ServiceInternalModelState) => {
		addEntityToOrder({ candidates: [service], entityName: 'services', to: suborderIndex, triggerRerender: triggerRerenderOnAddEntity });
		notify.successEntityAddedToOrder('services');
	};

	const isEmpty = services.length === 0;

	return (
		<>
			<Can passThrough I="add" an={`order.${suborderIndex}.services`}>
				{(can) => {
					return <ServiceSelectors appearance="button-list" onAddOrder={onAddService} disabled={!can} />;
				}}
			</Can>

			<EmptyServicesGate isEmpty={isEmpty} className={styles.emptySuborder}>
				<Table
					onPinningModelChange={setPinningModel}
					pinningModel={pinningModel}
					allData={services}
					getRowId={getOrderControllerEntityRowId}
					pageType="order"
					rowSelectionModel={selectionModel}
					onRowSelectionModelChange={onRowSelectionModelChange}
					columns={columns}
					className={styles.table}
					getCanSelectRow={getCanSelectRow}
				/>
			</EmptyServicesGate>
		</>
	);
};
