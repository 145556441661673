import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { CreateFilterTemplate, FilterTemplate, GetFilterTemplatePayload } from 'models/filter-template';
import { API_URL } from 'services/api';
import { filterTemplatesQueryKeys } from 'services/queryKeys';

import { setupInterceptorsTo } from '../auth/setupInterceptors';

const axiosInstance = axios.create({
	baseURL: 'https://unitb-appback.alterego.biz.ua/api/v1',
});

setupInterceptorsTo(axiosInstance);

export const filterTemplatesSliceApi = createApi({
	reducerPath: 'filterTemplates',
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	tagTypes: [filterTemplatesQueryKeys.filterTemplates()],
	endpoints: (builder) => ({
		getFilterTemplate: builder.query<FilterTemplate[], GetFilterTemplatePayload>({
			queryFn: async ({ userId, type }) => {
				try {
					const response = await axiosInstance.get<FilterTemplate[]>(`/filter-templates?userId=${userId}&type=${type}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: () => [filterTemplatesQueryKeys.filterTemplates()],
		}),
		createFilterTemplate: builder.mutation<FilterTemplate, CreateFilterTemplate>({
			queryFn: async (dto) => {
				try {
					const response = await axiosInstance.post<FilterTemplate>('/filter-templates', dto);

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data,
					};
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: () => [filterTemplatesQueryKeys.filterTemplates()],
		}),
		deleteFilterTemplate: builder.mutation<void, string>({
			queryFn: async (templateId) => {
				try {
					const response = await axiosInstance.delete<FilterTemplate>(`/filter-templates/${templateId}`);

					if (response.status !== 204) {
						throw new Error(response.statusText);
					}

					return {
						data: undefined,
					};
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: () => [filterTemplatesQueryKeys.filterTemplates()],
		}),
	}),
});

export const { useGetFilterTemplateQuery, useCreateFilterTemplateMutation, useDeleteFilterTemplateMutation } = filterTemplatesSliceApi;
