import clsx from 'clsx';
import Counter from 'components/Counter';
import React, { MouseEvent } from 'react';
import { formatNumberToUAH, numberify } from 'utils/shared';

import styles from './styles.module.css';
import { ItemsProps } from './types';

export const Item: React.FC<ItemsProps> = ({
	onClick,
	onDelete,
	onQuantityChange,
	initialValue: counter = 1,
	brand,
	title,
	price,
	unit,
	category,
	withDeleteButton = false,
	className,
}) => {
	const totalPrice = formatNumberToUAH(numberify(price) * numberify(counter));

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		onClick?.();
	};
	const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onDelete?.();
	};

	const onBlur = () => {
		if (!counter) {
			onQuantityChange(1);
		}
	};

	return (
		<div className={clsx(styles.productItem, className)}>
			<div className={styles.textContentWrapper} onClick={handleClick}>
				<p className={clsx(styles.brand, 'line-clamp text-md-medium')}>{brand.title}</p>
				<p className={clsx(styles.product, 'line-clamp')}>{title}</p>
				{!!category && <p className={clsx(styles.product, 'line-clamp')}>{category}</p>}
				<strong className={styles.price}>{totalPrice}</strong>
				<button type="button" className={clsx(styles.unitButton, 'line-clamp', styles.active)}>
					{unit}
				</button>
			</div>

			<Counter
				withDeleteButton={withDeleteButton}
				onDelete={handleDelete}
				onQuantityChange={onQuantityChange}
				value={counter}
				onBlur={onBlur}
			/>
		</div>
	);
};

export default Item;
