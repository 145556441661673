import { useOrderColumns } from 'columns/order';
import CancelSuborderReservationAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelSuborderReservationAlertDialogue';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import SaveErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/SaveErrorAlertDialogue';
import Spinner from 'components/Spinner';
import type {
	ColumnDefinition,
	OnPinningModelChange,
	OnVisibilityModelChange,
	PinningModel,
	VisibilityModel,
} from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Suborder } from 'models/order';
import type { CatalogueService } from 'models/service';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import type { OrderControllerState, SuborderTab } from 'pages/OrderRework/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/OrderRework/OrderController/lib/dto';
import { transformOrderToRootOrderTab, transformSuborderToSuborderTab } from 'pages/OrderRework/OrderController/lib/transform';
import React from 'react';
import {
	useCreateOrderMutation,
	useGetServicesQuery,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateSubOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';

interface ChildrenProps<TData> {
	productColumns: ColumnDefinition<TData>;
	visibilityModel: VisibilityModel;
	pinningModel: PinningModel;
	onPinningModelChange: OnPinningModelChange;
	onVisibilityModelChange: OnVisibilityModelChange;
	visibilityModelSaveConfigKey: string;
	onOrderSaveSafe: VoidCallback;
	onOrderReservationSafe: VoidCallback;
}

interface SuborderPanelProps<TData> {
	children: (props: ChildrenProps<TData>) => JSX.Element;
	suborderIndex: number;
}

const SuborderPanel = <TData,>({ children, suborderIndex }: SuborderPanelProps<TData>) => {
	const { handleSubmit, setValue, resetRollbackSnapshot } = useOrderOperationMethods();
	const { columns, pinningModel, setPinningModel, setVisibilityModel, visibilityModel, visibilityModelSaveConfigKey } = useOrderColumns({
		suborderIndex: suborderIndex,
		adjustSaveKey: '/split/products/' + suborderIndex,
	});

	const dialogue = useOrderAlertDialogue();
	const notify = useOrderNotifications();

	const [updateSuborderAsync, updateOrderRequest] = useUpdateSubOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();
	const { data: services } = useGetServicesQuery<{ data: Record<string, CatalogueService> }>();

	const saveOrders = async (values: OrderControllerState) => {
		const orders = values.suborders
			.map((order) => {
				const hasProducts = Object.values(order.data.products ?? {}).length > 0;
				const hasServices = Object.values(order.data.services ?? {}).length > 0;

				if (!hasProducts && !hasServices) return null;

				const isNew = !order.data.id;
				const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);
				const skip = order.data.isReserved;

				return { isNew, dto, skip };
			})
			.filter(Boolean);

		const response = await Promise.all(
			orders.map(({ isNew, dto, skip }) => {
				if (skip) return Promise.resolve(dto);

				return isNew ? createOrderAsync(dto).unwrap() : updateSuborderAsync(dto).unwrap();
			}),
		);

		return response;
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		dialogue.open('saveSplitting', {
			onSubmit: async () => {
				const response = await saveOrders(values);
				const [rootSuborder, ...restSuborders] = response;
				const rootOrderTab = transformOrderToRootOrderTab({ order: rootSuborder, services });
				const restSubordersTabs = restSuborders.map((suborder, index) => transformSuborderToSuborderTab({ suborder, services, index }));
				resetRollbackSnapshot();
				setValue('suborders', [rootOrderTab, ...restSubordersTabs]);

				notify.successOrderSaveSplitting();
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[suborderIndex].data.isReserved;

		if (isReserved) {
			dialogue.open('suborderCancelReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[suborderIndex] ?? {};

					const response = (await setFromReserveAsync({ id: order.data.id }).unwrap()) as { data: Suborder };
					const orderModel = transformSuborderToSuborderTab({ suborder: response.data, services, index: order.data.index });

					const newSuborder = {
						...order,
						data: {
							...orderModel.data,
							...order.data,
							status: orderModel.data.status,
							isReserved: orderModel.data.isReserved,
						},
					} as SuborderTab;

					const newSuborders = [...values.suborders];
					newSuborders.splice(suborderIndex, 1, newSuborder);
					setValue('suborders', newSuborders);

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		} else {
			dialogue.open('suborderReservation', {
				onSubmit: async () => {
					const response = await saveOrders(values);
					const [rootSuborder, ...restSuborders] = response;
					const rootOrderTab = transformOrderToRootOrderTab({ order: rootSuborder, services });
					const restSubordersTabs = restSuborders.map((suborder, index) => transformSuborderToSuborderTab({ suborder, services, index }));

					const order = response[suborderIndex];
					notify.successOrderSaveSplitting();

					const reservationResponse = (await setOnReserveAsync(order.id).unwrap()) as { data: Suborder };
					const orderModel = transformSuborderToSuborderTab({
						suborder: reservationResponse.data,
						services,
						index: suborderIndex - 1,
					});

					const currentSuborder = restSubordersTabs[suborderIndex - 1];
					const newSuborder = {
						...currentSuborder,
						data: {
							...orderModel.data,
							...currentSuborder.data,
							status: orderModel.data.status,
							isReserved: Boolean(orderModel.data.isReserved),
						},
					} as SuborderTab;

					const newSuborders = [rootOrderTab, ...restSubordersTabs];
					newSuborders.splice(suborderIndex, 1, newSuborder);

					resetRollbackSnapshot();
					setValue('suborders', newSuborders);
					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
				data: suborderIndex,
			});
		}
	});

	const onOrderReservationSafe = useStopPropagationCallback(handleOrderReservation);
	const onOrderSaveSafe = useStopPropagationCallback(handleOrderSave);

	return (
		<>
			{children({
				productColumns: columns,
				onPinningModelChange: setPinningModel,
				onVisibilityModelChange: setVisibilityModel,
				pinningModel,
				visibilityModel,
				visibilityModelSaveConfigKey,
				onOrderSaveSafe,
				onOrderReservationSafe,
			})}

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}

			{/* @ts-ignore */}
			{createOrderRequest.isError && <SaveErrorAlertDialogue message={createOrderRequest.error?.message} onClose={createOrderRequest.reset} />}
			{/* @ts-ignore */}
			{updateOrderRequest.isError && <SaveErrorAlertDialogue message={updateOrderRequest.error?.message} onClose={updateOrderRequest.reset} />}

			{onReserveRequest.isError && (
				<ReservationErrorAlertDialogue
					// @ts-ignore
					message={onReserveRequest.error.message}
					onClose={onReserveRequest.reset}
				/>
			)}
			{fromReserveRequest.isError && (
				<CancelSuborderReservationAlertDialogue
					// @ts-ignore
					message={fromReserveRequest.error.message}
					onClose={fromReserveRequest.reset}
					data={suborderIndex}
				/>
			)}
		</>
	);
};

export default SuborderPanel;
