import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import CancelReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelReservationErrorAlertDialogue';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import SaveErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/SaveErrorAlertDialogue';
import OrderAdditionalDrawer from 'components/OrderPageComponents/OrderAdditionalDrawer';
import Spinner from 'components/Spinner';
import { CanUser } from 'contexts/AbilityContext';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { isAfter, startOfDay } from 'date-fns';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order } from 'models/order';
import { CatalogueService } from 'models/service';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import { Can } from 'pages/OrderRework/OrderAbility/provider';
import { OrderControllerState, SuborderTab } from 'pages/OrderRework/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/OrderRework/OrderController/lib/dto';
import { transformOrderToRootOrderTab } from 'pages/OrderRework/OrderController/lib/transform';
import React, { lazy, Suspense } from 'react';
import File from 'static/images/file-attachment-02.svg';
import Save from 'static/images/file-in.svg';
import Lock from 'static/images/lock.svg';
import Plus from 'static/images/plusBig.svg';
import Truck from 'static/images/truck-white.svg';
import {
	useCreateOrderMutation,
	useGetServicesQuery,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';

import styles from './style.module.css';
import { IProps } from './types';

const BusinessOffer = lazy(() => import('components/BusinessOffer'));
const Modal = lazy(() => import('components/Modal'));
const ModalHeader = lazy(() => import('components/Modal/ModalHeader'));

const OrderTopBar: React.FC<IProps> = ({ onRequestLogistics }) => {
	const { getValues, handleSubmit, setValue } = useOrderOperationMethods();

	const [updateOrderAsync, updateOrderRequest] = useUpdateOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();
	const { data: services } = useGetServicesQuery<{ data: Record<string, CatalogueService> }>();

	const dialogue = useOrderAlertDialogue();
	const drawerInterface = useBoolean();
	const businessOfferInterface = useBoolean();
	const notify = useOrderNotifications();
	const router = useOrderRouter();

	const drawerInterfaceToggle = useStopPropagationCallback(drawerInterface.toggle);
	const businessOfferInterfaceToggle = useStopPropagationCallback(businessOfferInterface.toggle);

	const rootOrder = getValues(`suborders.${0}.data`);
	const orderId = rootOrder.id;
	const isReservedOrder = rootOrder.isReserved;
	const today = new Date();
	const isOutdated = isAfter(startOfDay(today), startOfDay(rootOrder.date));

	const handleMakeBusinessOfferSafe = (can: boolean) => {
		if (!can) {
			dialogue.open('businessOfferIsNotAvailable');
		} else {
			businessOfferInterface.toggle();
		}
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		const order = values?.suborders?.[0]?.data ?? {};
		const isNewOrder = !order.id;
		const modalKey = isNewOrder ? 'createNewOrder' : 'saveMainOrder';

		dialogue.open(modalKey, {
			onSubmit: async () => {
				if (isNewOrder) {
					const createOrderDto = transformOrderToCreateOrderDTO(order);
					const response = await createOrderAsync(createOrderDto).unwrap();
					router.toCertainOrder(response.id, { replace: true });
				} else {
					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					const response = await updateOrderAsync(updateOrderDTO).unwrap();
					const orderModel = transformOrderToRootOrderTab({ order: response, services });
					const suborders = getValues('suborders');
					setValue('suborders', [orderModel, ...(suborders.slice(1) ?? [])]);
				}

				notify.successOrderSave();
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[0].data.isReserved;

		if (isReserved) {
			dialogue.open('mainOrderCancelReservation', {
				onSubmit: async () => {
					const suborders = values?.suborders ?? [];
					const order = suborders?.[0] ?? {};

					const response = (await setFromReserveAsync({ id: order.data.id }).unwrap()) as { data: Order };
					const orderModel = transformOrderToRootOrderTab({ order: response.data, services });

					const newRootOrder = {
						...order,
						data: {
							...orderModel.data,
							...order.data,
							status: orderModel.data.status,
							isReserved: orderModel.data.isReserved,
						},
					} as SuborderTab;

					setValue('suborders', [newRootOrder, ...(suborders.slice(1) ?? [])]);

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
			});
		} else {
			dialogue.open('mainOrderReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[0]?.data ?? {};

					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					await updateOrderAsync(updateOrderDTO).unwrap();
					const response = (await setOnReserveAsync({ id: order.id }).unwrap()) as { data: Order };
					const orderModel = transformOrderToRootOrderTab({ order: response.data, services });
					const suborders = getValues('suborders');
					setValue('suborders', [orderModel, ...(suborders.slice(1) ?? [])]);

					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
			});
		}
	});

	return (
		<>
			<div className={styles.topBarWrapper}>
				<Can passThrough I="click" on={`order.${0}.request-logistic`}>
					{(can) => (
						<Button
							onClick={onRequestLogistics}
							icon={Truck}
							variant="rounded"
							background="var(--primary-500)"
							hoverBg="#000"
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.save`}>
					{(can) => (
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handleOrderSave();
							}}
							icon={Save}
							variant="rounded"
							background={'#d71b87'}
							hoverBg="#000"
							className={clsx(styles.rounded, { ['disabled-button']: !can, [styles.saveButton]: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.business-offer`}>
					{(can) => (
						<Button
							variant="rounded"
							background="var(--primary-500)"
							hoverBg="#000"
							onClick={() => handleMakeBusinessOfferSafe(can)}
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							icon={File}
							disabled={!can}
						/>
					)}
				</Can>
				<CanUser passThrough do="edit" an="orders.order.reserve">
					{(canUser) => {
						return (
							<Can passThrough I="click" on={`order.${0}.reserve`}>
								{(can) => (
									<Button
										background="var(--error-50)"
										onClick={(e) => {
											e.stopPropagation();
											if (isOutdated && !isReservedOrder) {
												dialogue.open('needUpdateOrderDate', {
													data: rootOrder.date,
												});
											} else {
												handleOrderReservation();
											}
										}}
										icon={Lock}
										variant="rounded"
										className={clsx(styles.reserv, styles.rounded, {
											[styles.active]: isReservedOrder,
											['disabled-button']: !canUser || !can,
										})}
										disabled={!canUser || !can}
									/>
								)}
							</Can>
						);
					}}
				</CanUser>

				<Can passThrough I="click" on={`order.${0}.drawer`}>
					{(can) => (
						<Button
							onClick={drawerInterfaceToggle}
							icon={Plus}
							variant="rounded"
							background={'var(--primary-500)'}
							hoverBg="#000"
							className={clsx(styles.btn, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
			</div>

			<Suspense fallback={<Spinner />}>
				<Modal
					open={businessOfferInterface.isOn}
					onClose={businessOfferInterfaceToggle}
					width="640px"
					maxWidth="unset"
					className={styles.labels}
				>
					<ModalHeader title={'Створити комерційну пропозицію'} onXCloseClick={businessOfferInterfaceToggle} />
					<span className={styles.divider}></span>

					<BusinessOffer orderId={orderId} />
				</Modal>
			</Suspense>

			<Suspense fallback={<Spinner />}>
				{drawerInterface.isOn && (
					<Drawer open={drawerInterface.isOn} title={'Додати'} onClose={drawerInterfaceToggle}>
						<OrderAdditionalDrawer />
					</Drawer>
				)}
			</Suspense>

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}
			{/* @ts-ignore */}
			{createOrderRequest.isError && <SaveErrorAlertDialogue onClose={createOrderRequest.reset} message={createOrderRequest.error?.message} />}
			{/* @ts-ignore */}
			{updateOrderRequest.isError && <SaveErrorAlertDialogue onClose={updateOrderRequest.reset} message={updateOrderRequest.error?.message} />}
			{onReserveRequest.isError && (
				<ReservationErrorAlertDialogue
					// @ts-ignore
					message={onReserveRequest.error.message}
					onClose={onReserveRequest.reset}
				/>
			)}
			{fromReserveRequest.isError && (
				<CancelReservationErrorAlertDialogue
					// @ts-ignore
					message={fromReserveRequest.error.message}
					onClose={fromReserveRequest.reset}
				/>
			)}
		</>
	);
};

export default OrderTopBar;
