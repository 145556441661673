import { useDebouncedCallbackWithCleanUp } from 'hooks/useDebouncedCallbackWithCleanUp';
import { useLoadableOptions } from 'hooks/useLoadableOptions';
import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import type { IProps, Suggestion } from '../types';
import { filterIncluded, getHydrateStateData, mergeSuggestions, resolveDisplayValue } from '../utils';
import { useStateController } from './_useStateController';

export function useController(props: IProps) {
	const { debounceTime, readonly = false, disabled = false, valuesList, loadAsyncOptions, onChange } = props;

	const { suggestion, hasReachedLimit, onUnselect, setSuggestion } = useStateController(props);
	const [state, setState] = useState(() => getHydrateStateData(valuesList, suggestion));

	const { options, isLoading, refetch, isError, cancel } = useLoadableOptions<Suggestion[]>({
		asyncLoader: loadAsyncOptions,
		skip: !state.showSuggestions,
	});

	const resolvedOptions = loadAsyncOptions ? options : valuesList;

	useEffect(() => {
		if (resolvedOptions?.length) {
			const newState = getHydrateStateData(resolvedOptions, suggestion);

			setState(newState);
		}
	}, [resolvedOptions, suggestion]);

	const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newUserInput = event.currentTarget.value;
		onChange?.(event, newUserInput);

		setState((prev) => ({ ...prev, userInput: newUserInput, shouldDebounce: true }));
	};

	const handleFilterSuggestions = useCallback(
		(input: string) => {
			setState((prev) => ({
				...prev,
				filteredSuggestions: filterIncluded(resolvedOptions, input),
				activeSuggestion: 0,
				showSuggestions: true,
			}));
		},
		[resolvedOptions],
	);

	const { debouncedCallback, debounceCleanUp } = useDebouncedCallbackWithCleanUp(handleFilterSuggestions, debounceTime);

	useEffect(() => {
		if (state.userInput && state.shouldDebounce) {
			debouncedCallback(state.userInput);
		}
	}, [state.userInput, state.shouldDebounce]);

	const closeDropdown = () => {
		cancel();
		setState((prev) => ({ ...prev, showSuggestions: false, hasFocus: false }));
	};

	const onClick = (newSuggestion: Suggestion | string) => {
		debounceCleanUp();

		if (hasReachedLimit) return;

		const isArray = Array.isArray(suggestion);
		const resolvedSuggestions = isArray ? suggestion : ([suggestion] as string[] | Suggestion[]);
		const mergedSuggestions = mergeSuggestions(newSuggestion, resolvedSuggestions);
		const newUserInput = isArray ? '' : resolveDisplayValue(newSuggestion);

		setState({
			activeSuggestion: 0,
			filteredSuggestions: mergedSuggestions,
			showSuggestions: false,
			userInput: newUserInput,
			shouldDebounce: false,
			hasFocus: false,
		});
		setSuggestion?.(newSuggestion);
	};

	const resetSuggestion = () => {
		debounceCleanUp();

		setState({
			activeSuggestion: 0,
			filteredSuggestions: [],
			showSuggestions: false,
			userInput: '',
			shouldDebounce: true,
			hasFocus: false,
		});
		setSuggestion?.(null);
	};

	const onKeyDown = (event: KeyboardEvent) => {
		const { filteredSuggestions, activeSuggestion } = state;
		const optionsList = state.userInput ? filteredSuggestions : resolvedOptions;

		if (event.key === 'Enter') {
			debounceCleanUp();

			if (hasReachedLimit) return;

			const newSuggestion = optionsList[activeSuggestion];

			const isArray = Array.isArray(suggestion);
			const resolvedSuggestions = isArray ? suggestion : ([suggestion] as string[] | Suggestion[]);
			const mergedSuggestions = mergeSuggestions(newSuggestion, resolvedSuggestions);
			const newUserInput = isArray ? '' : resolveDisplayValue(newSuggestion);

			setState((prev) => ({
				...prev,
				activeSuggestion: 0,
				filteredSuggestions: mergedSuggestions,
				showSuggestions: false,
				userInput: newUserInput,
				shouldDebounce: false,
			}));
			setSuggestion?.(newSuggestion);
		}
		if (event.key === 'ArrowUp') {
			setState((prev) => ({ ...prev, activeSuggestion: activeSuggestion === 0 ? optionsList.length - 1 : activeSuggestion - 1 }));
		}

		if (event.key === 'ArrowDown') {
			setState((prev) => ({ ...prev, activeSuggestion: activeSuggestion === optionsList.length - 1 ? 0 : activeSuggestion + 1 }));
		}
	};

	const onFocus = (event: FocusEvent<HTMLInputElement>) => {
		if (disabled || readonly) {
			event.target.blur();
			return;
		}

		setState((prev) => {
			const shouldShowSuggestions = Array.isArray(suggestion) ? true : resolveDisplayValue(suggestion) !== state.userInput;

			return { ...prev, showSuggestions: shouldShowSuggestions, hasFocus: true };
		});
	};

	return {
		state: { ...state, suggestion },
		onClick,
		onInputChange,
		resetSuggestion,
		onKeyDown,
		onFocus,
		onUnselect,
		closeDropdown,
		isLoading,
		refetch,
		isError,
		options: resolvedOptions,
		hasReachedLimit,
	};
}
