import { z } from 'zod';

export const ProductSearchResultDataItemSchema = z.object({
	categoryId: z.string(),
	categoryName: z.string(),
	productName: z.string(),
	productCode: z.string().optional(),
});

export type ProductSearchResultDataItem = z.infer<typeof ProductSearchResultDataItemSchema>;
export type ProductSearchResult = Record<string, ProductSearchResultDataItem[]>;
export type NormalizedProductSearchResultDataItem = { rootCategoryName: string; data: ProductSearchResultDataItem[] };
export type NormalizedProductSearchResult = NormalizedProductSearchResultDataItem[];
