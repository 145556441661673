import clsx from 'clsx';
import Button from 'components/Button';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import SettingButton from 'components/Table/Filters/SettingsButton';
import type { ColumnDefinition, VisibilityModel } from 'components/Table/lib/table/types/table';
import { breakPoints } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React from 'react';
import MediaQuery from 'react-responsive';
import DollarIcon from 'static/images/dollar.svg';
import FullScreen from 'static/images/expand.svg';
import Trash from 'static/images/trashOrders.svg';

import { Can } from '../OrderAbility/provider';
import PriceTypes from '../PriceTypes';
import styles from './styles.module.css';

export interface OrderEntitiesControlPanelProps<TData> {
	onFullScreenChange: VoidCallback;
	onVisibilityModelChange: VoidCallback;
	onDelete: VoidCallback;
	hasSelectedEntities: boolean;
	visibilityModel: VisibilityModel;
	entityColumns: ColumnDefinition<TData>;
	isFullScreen: boolean;
	isChangePriceAvailable: boolean;
	children?: React.ReactNode;
	isChangePriceMode?: boolean;
	onChangePriceModeToggle: VoidCallback;
	visibilitySaveConfigKey: string;
	isPriceTypeColumnInsertionDisabled?: boolean;
	suborderIndex: number;
}

const OrderEntitiesControlPanel = <TData,>({
	onFullScreenChange,
	onVisibilityModelChange,
	onDelete,
	hasSelectedEntities,
	visibilityModel,
	entityColumns,
	isChangePriceAvailable,
	children,
	onChangePriceModeToggle,
	isChangePriceMode,
	visibilitySaveConfigKey,
	isPriceTypeColumnInsertionDisabled,
	suborderIndex,
}: OrderEntitiesControlPanelProps<TData>) => {
	const visibilityModelInterface = useBoolean();
	const onEntityDelete = useStopPropagationCallback<HTMLButtonElement>(onDelete);
	const toggleVisibilityModelInterface = useStopPropagationCallback<HTMLButtonElement>(visibilityModelInterface.toggle);

	const isDeleteButtonDisabled = !hasSelectedEntities || isChangePriceMode;
	const isPriceModeButtonDisabled = !isChangePriceAvailable;
	const handlePriceChangeToggle = useStopPropagationCallback(onChangePriceModeToggle);

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				{children}
				<div className={styles.configButtonsWrapper}>
					<Button
						onClick={onEntityDelete}
						icon={Trash}
						variant="bordered"
						className={clsx({ [styles.disabled]: isDeleteButtonDisabled })}
						disabled={isDeleteButtonDisabled}
					/>
					<Button
						icon={DollarIcon}
						variant="bordered"
						onClick={handlePriceChangeToggle}
						className={clsx({ [styles.disabled]: isPriceModeButtonDisabled })}
						disabled={isPriceModeButtonDisabled}
					/>

					<SettingButton onClick={toggleVisibilityModelInterface}>
						<ColumnsSettingsMenu
							visibilityModel={visibilityModel}
							open={visibilityModelInterface.isOn}
							onClose={toggleVisibilityModelInterface}
							columns={entityColumns}
							setColumns={onVisibilityModelChange}
						/>
					</SettingButton>

					<Button onClick={onFullScreenChange} icon={FullScreen} variant="bordered" />
				</div>

				<Can passThrough I="change" an={`order.${suborderIndex}.columns`}>
					{(can) => (
						<PriceTypes
							suborderIndex={suborderIndex}
							hydrateStateKey={visibilitySaveConfigKey}
							justify="flex-end"
							onVisibilityModelChange={onVisibilityModelChange}
							className={styles.priceTypesWrapper}
							disableAll={!can || isPriceTypeColumnInsertionDisabled}
						/>
					)}
				</Can>
			</MediaQuery>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<Can passThrough I="change" an={`order.${suborderIndex}.columns`}>
					{(can) => (
						<PriceTypes
							suborderIndex={suborderIndex}
							hydrateStateKey={visibilitySaveConfigKey}
							justify="flex-start"
							onVisibilityModelChange={onVisibilityModelChange}
							className={styles.priceTypesWrapper}
							disableAll={!can || isPriceTypeColumnInsertionDisabled}
						/>
					)}
				</Can>
			</MediaQuery>
		</>
	);
};

export default OrderEntitiesControlPanel;
