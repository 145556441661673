import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Whoami } from 'models/auth';

import type { IState } from './typed';

const initialState = {
	user: null,
} as IState;

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoggedInUser: (state, action: PayloadAction<{ user: Whoami }>) => {
			state.user = action.payload.user;
		},
		clearLoggedInUser: (state) => {
			state.user = null;
		},
	},
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
