import Button from 'components/Button';
import Modal from 'components/Modal';
import type { IProps as ModalProps } from 'components/Modal/types';
import AvailabilityChecker from 'components/OrderCreatePageComponents/ProductConfigurationTool/AvailabilityChecker';
import ConfigurationToolHeader from 'components/OrderCreatePageComponents/ProductConfigurationTool/ConfigurationToolHeader';
import QuantitySelector from 'components/OrderCreatePageComponents/ProductConfigurationTool/QuantitySelector';
import type { ProductUnit } from 'models/product';
import { useOrderNotifications } from 'pages/OrderRework/hooks/useOrderNotifications';
import type { ProductInternalModelState } from 'pages/OrderRework/OrderController';
import React, { useState } from 'react';
import CircleCheckIcon from 'static/images/check-rounded.svg';
import { calculateTotalPrice, numberify, roundNumber } from 'utils/shared';

import styles from './styles.module.css';

type TransferProductDetailsModalProps = Pick<ModalProps, 'onClose'> & {
	entity: ProductInternalModelState;
	onSubmit: (product: ProductInternalModelState) => void;
	stock: { value: string; label: string };
};

const TransferProductDetailsModal: React.FC<TransferProductDetailsModalProps> = ({ onClose, entity, stock, onSubmit }) => {
	const notify = useOrderNotifications();
	const { brand, title, amount, unit, price, freeLeftovers, reserves } = entity ?? ({} as ProductInternalModelState);

	const [state, setState] = useState({
		quantity: Number(amount),
		unit,
	});

	const handleMeasureUnitChange = (unitProp: ProductUnit) => {
		setState({ ...state, unit: unitProp });
	};
	const handleQuantityChange = (quantity: number) => {
		setState({ ...state, quantity });
	};

	const handleAction = () => {
		if (quantity === 0) {
			return notify.errorEntityInvalidAmount();
		}
		onSubmit({ ...entity, amount: String(state.quantity), unit });
	};

	const quantity = numberify(state.quantity);
	const totalPrice = roundNumber(calculateTotalPrice({ price: Number(price), amount: quantity }), 2);

	return (
		<Modal open stackable onClose={onClose} maxWidth="90vw" maxHeight="90vh" minHeight="500px" className={styles.modal}>
			<div className={styles.wrapper}>
				<ConfigurationToolHeader title={brand.title} subtitle={title} onXCloseClick={onClose} />

				<div className={styles.contentWrapper}>
					<QuantitySelector
						totalPrice={totalPrice}
						quantity={quantity}
						unit={state.unit}
						onMeasureUnitChange={handleMeasureUnitChange}
						onQuantityChange={handleQuantityChange}
						className={styles.quantitySelector}
					/>
					<div className={styles.actionButtonWrapper}>
						<Button onClick={handleAction} variant="smallRounded" text="Вибрати" icon={CircleCheckIcon} className={styles.actionButton} />
					</div>

					<AvailabilityChecker
						freeLeftovers={freeLeftovers}
						reserves={reserves}
						stockId={stock?.value ?? ''}
						stockName={stock?.label ?? ''}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default TransferProductDetailsModal;
