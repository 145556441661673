import { createContext, useContext } from 'react';
export interface MainSearchContext {
	items: { title: string; id: string }[];
	onChange: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
	onOptionClick: () => void;
	isDropdownOpened: boolean;
	isLoading: boolean;
	query: string;
	queryKey: string;
	initialQuery: string;
	openDropdown: () => void;
	closeDropdown: () => void;
}

const Context = createContext<MainSearchContext>(null);

export const useMainSearch = () => {
	const ctx = useContext<MainSearchContext>(Context);

	if (!ctx) throw new Error('Must be inside <MainSearch />');

	return ctx;
};

export const MainSearchProvider = Context.Provider;
