const { log, error, warn } = console;

export const logger = {
	info: log,
	error,
	warn,
	debugOrder: (errors: Record<string, AnyArg>) => {
		const hasErrors = Object.keys(errors || {}).length > 0;

		const message = hasErrors ? 'Has internal validation errors' : 'No internal validation errors';

		const styles = hasErrors ? 'background: red; color: white;' : 'background: green; color: white;';

		logger.info(`%c ${message}`, styles, errors);
	},
};
