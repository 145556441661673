import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import OrderExtraActionButton from 'components/OrderPageComponents/OrderExtraActionButton';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints, ROUTES_URLS } from 'const';
import { useAdjustableColumns } from 'hooks/useAdustableColumns';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import type { OrderProduct } from 'models/product';
import { adjustableColumnsIcons } from 'pages/CreateOrder/icons';
import DetailedEntitySpitData from 'pages/OrderRework/components/DetailedEntitySpitData';
import DisabledEntityTooltip from 'pages/OrderRework/components/DisabledEntityTooltip';
import OrderProductAmountInput from 'pages/OrderRework/components/OrderProductAmountInput';
import OrderProductPriceInput from 'pages/OrderRework/components/OrderProductPriceInput';
import OrderProductSumDisplayField from 'pages/OrderRework/components/OrderProductSumDisplayField';
import ProductAvailabilityController from 'pages/OrderRework/components/ProductAvailabilityController';
import { Can } from 'pages/OrderRework/OrderAbility/provider';
import { getEntityDIYPrice } from 'pages/OrderRework/OrderController/lib/utils';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { usePriceTypeColumns } from './priceType';

export type UseOrderColumnsProps = {
	adjustSaveKey?: string;
	suborderIndex?: number;
};

const columnHelper = createColumnHelper<OrderProduct>();

export const useOrderColumns = (props?: UseOrderColumnsProps) => {
	const location = useLocation();
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });
	const { adjustSaveKey, suborderIndex = 0 } = props ?? {};

	const findSalePrice = (prices) => {
		const salePrice = prices?.find((price) => price.typePrice.id === 'a129be59-31f8-4bee-9f3b-cf4e76495df8');
		return salePrice;
	};

	const findGoodPrice = (prices) => {
		const goodPrice = prices?.find((price) => price.typePrice.id === 'e4ea81a3-8307-11ed-b8b4-932465dbe65a');
		return goodPrice;
	};

	const priceTypeColumns = usePriceTypeColumns({ suborderIndex, isDesktop, isTablet });

	const initialColumns = useMemo(() => {
		const isOnSplitViewPage =
			location.pathname.endsWith(ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT) ||
			location.pathname.endsWith(ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT);

		const abilityOrderKey = isOnSplitViewPage ? 'suborder' : 'order';

		const defColumns = [
			columnHelper.accessor((row) => row.id, {
				id: 'id',
				header: () => {
					return <HeaderCell>{'Id'}</HeaderCell>;
				},
				cell: (cell) => <RegularCell>{`${cell.row.index + 1}.`}</RegularCell>,
				size: 88,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Id',
					getMobileHeaderCell: () => 'Id',
					icon: <adjustableColumnsIcons.Uuid />,
				},
			}),
			columnHelper.accessor('select-col', {
				id: 'select-col',
				header: ({ table }) => {
					return (
						<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.products`}>
							{(can) => (
								<HeaderCell>
									<TableCheckbox
										checked={table.getIsAllRowsSelected()}
										indeterminate={table.getIsSomeRowsSelected()}
										onChange={table.getToggleAllRowsSelectedHandler()}
										disabled={!can}
									/>
								</HeaderCell>
							)}
						</Can>
					);
				},
				cell: (cell) => {
					return (
						<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.products`}>
							{(can) => (
								<RegularCell>
									<TableCheckbox
										checked={cell.row.getIsSelected()}
										disabled={!can || !cell.row.getCanSelect()}
										onChange={cell.row.getToggleSelectedHandler()}
									/>
								</RegularCell>
							)}
						</Can>
					);
				},
				size: 88,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getMobileHeaderCell: () => '',
					headerCellContentOffset: '31px',
				},
			}),
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код товару</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Код товару',
					getMobileHeaderCell: () => 'Код товару',
					icon: <adjustableColumnsIcons.FinalCost />,
				},
			}),
			...(isTablet
				? [
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell>Товар</HeaderCell>,
							cell: ({ row }) => {
								return (
									<>
										<RegularCell pr="12px" gap="8px">
											<span className="tablet-cell text-left">
												<span>{row.original.brand?.title}</span>
												<span>{row.original?.title}</span>
											</span>
											{!row.getCanSelect() && (
												<DisabledEntityTooltip>
													<DetailedEntitySpitData entityId={row.original.id} entityType="products" />
												</DisabledEntityTooltip>
											)}
											<Can passThrough I="change" an="order.products.table">
												{(can) => (
													<OrderExtraActionButton suborderIndex={suborderIndex} product={row.original} disabled={!can} />
												)}
											</Can>
										</RegularCell>
									</>
								);
							},
							size: 420,
							enableHiding: true,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								getMobileHeaderCell: () => 'Товар',
								icon: <adjustableColumnsIcons.Product />,
							},
						}),
				  ]
				: [
						columnHelper.accessor((row) => row.title, {
							id: 'title',
							header: () => <HeaderCell className="text-left">Товар</HeaderCell>,
							cell: (cell) => (
								<RegularCell className={clsx({ 'locked-entity-xl': !cell.row.getCanSelect() })}>
									<span>{cell.getValue()}</span>

									{!cell.row.getCanSelect() && (
										<DisabledEntityTooltip>
											<DetailedEntitySpitData entityId={cell.row.original.id} entityType="products" />
										</DisabledEntityTooltip>
									)}

									<Can passThrough I="change" an={`order.${suborderIndex}.products.table`}>
										{(can) => (
											<OrderExtraActionButton
												suborderIndex={suborderIndex}
												product={cell.row.original}
												disabled={!can}
												className={clsx({ 'ml-0': !cell.row.getCanSelect() })}
											/>
										)}
									</Can>
								</RegularCell>
							),
							enableHiding: true,
							size: 420,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => '',
								getMobileHeaderCell: () => '',
								icon: <adjustableColumnsIcons.Product />,
							},
						}),
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell className="text-left">Бренд</HeaderCell>,
							cell: (cell) => <RegularCell>{cell.getValue()?.title}</RegularCell>,
							enableHiding: true,
							size: 200,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								getMobileHeaderCell: () => 'Товар',
								icon: <adjustableColumnsIcons.Brand />,
							},
						}),
				  ]),
			columnHelper.accessor((row) => row.amount, {
				id: 'amount',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>К-ть</HeaderCell>,
				cell: (cell) => {
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="color-grey-400 measure-unit">
							<OrderProductAmountInput entityId={cell.row.original.id} suborderIndex={suborderIndex} />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'К-ть',
					getMobileHeaderCell: () => 'К-ть',
					icon: <adjustableColumnsIcons.Quantity />,
				},
			}),
			columnHelper.accessor((row) => row.unit, {
				id: 'unit',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Од-ці</HeaderCell>,
				cell: (cell) => <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{cell.getValue()?.title}.</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Од-ці',
					getMobileHeaderCell: () => 'Од-ці',
					icon: <adjustableColumnsIcons.MeasureUnit />,
				},
			}),
			columnHelper.accessor(() => null, {
				id: 'price',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ціна</HeaderCell>,
				cell: (cell) => {
					return (
						<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
							<OrderProductPriceInput entityId={cell.row.original.id} suborderIndex={suborderIndex} />
						</RegularCell>
					);
				},
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна',
					getMobileHeaderCell: () => 'Ціна',
					icon: <adjustableColumnsIcons.Price />,
				},
			}),
			columnHelper.accessor((row) => getEntityDIYPrice(row, 'products'), {
				id: 'priceDIY',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ціна DIY</HeaderCell>,
				cell: (cell) => {
					return <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{cell.getValue()}</RegularCell>;
				},
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна DIY',
					getMobileHeaderCell: () => 'Ціна DIY',
					icon: <adjustableColumnsIcons.DiyPrice />,
				},
			}),
			/**
			 * ==========
			 * here we inject price type columns so they can be properly toggled
			 */
			...priceTypeColumns,
			/**
			 * =========
			 */
			columnHelper.accessor((row) => row.sum, {
				id: 'sum',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Сума</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						<OrderProductSumDisplayField entityId={cell.row.original.id} fieldIndex={cell.row.index} suborderIndex={suborderIndex} />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Сума',
					getMobileHeaderCell: () => 'Сума',
					icon: <adjustableColumnsIcons.TotalPrice />,
				},
			}),
			columnHelper.accessor((row) => row.reserves, {
				id: 'reserves',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Резерв</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })} className="highlighter-200">
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="reserves" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Резерв',
					getMobileHeaderCell: () => 'Резерв',
					icon: <adjustableColumnsIcons.InReserve />,
				},
			}),
			columnHelper.accessor((row) => row.leftovers, {
				id: 'leftovers',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Залишок</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="leftovers" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Залишок',
					getMobileHeaderCell: () => 'Залишок',
					icon: <adjustableColumnsIcons.Remaining />,
				},
			}),
			columnHelper.accessor((row) => row.freeLeftovers, {
				id: 'freeLeftovers',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Вільний залишок</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>
						<ProductAvailabilityController entity={cell.row.original} suborderIndex={suborderIndex} watchKey="freeLeftovers" />
					</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вільний залишок',
					getMobileHeaderCell: () => 'Вільний залишок',
					icon: <adjustableColumnsIcons.AvailableBalance />,
				},
			}),
			columnHelper.accessor((row) => row.deviation, {
				id: 'deviation',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Відхилення %</HeaderCell>,
				cell: (cell) => <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{cell.getValue()}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Відхилення %',
					getMobileHeaderCell: () => 'Відхилення %',
					icon: <adjustableColumnsIcons.Deviation />,
				},
			}),
			columnHelper.accessor('manualDiscount', {
				id: 'manualDiscount',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ручна знижка%</HeaderCell>,
				cell: () => <RegularCell {...((isDesktop || isTablet) && { justify: 'center' })}>{'—'}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ручна знижка%',
					getMobileHeaderCell: () => 'Ручна знижка%',
					icon: <adjustableColumnsIcons.ManualDiscount />,
				},
			}),
			columnHelper.accessor('favorablePrice', {
				id: 'favorablePrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Вигідна ціна</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...(isDesktop && { justify: 'center' })}>{findGoodPrice(cell.row.original.prices)?.price || '—'}</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вигідна ціна',
					getMobileHeaderCell: () => 'Вигідна ціна',
					icon: <adjustableColumnsIcons.FavorablePrice />,
				},
			}),
			columnHelper.accessor('promotionPrice', {
				id: 'promotionPrice',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Ціна акційна</HeaderCell>,
				cell: (cell) => (
					<RegularCell {...(isDesktop && { justify: 'center' })}>{findSalePrice(cell.row.original.prices)?.price || '—'}</RegularCell>
				),
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна акційна',
					getMobileHeaderCell: () => 'Ціна акційна',
					icon: <adjustableColumnsIcons.PromotionPrice />,
				},
			}),
			columnHelper.accessor((row) => row.profitability, {
				id: 'profitability',
				header: () => <HeaderCell {...((isDesktop || isTablet) && { justify: 'center' })}>Рентабельність</HeaderCell>,
				cell: (cell) => <RegularCell {...(isDesktop && { justify: 'center' })}>{cell.getValue()}</RegularCell>,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Рентабельність',
					getMobileHeaderCell: () => 'Рентабельність',
					icon: <adjustableColumnsIcons.Points />,
				},
			}),
		];

		return defColumns;
	}, [isTablet, isDesktop, suborderIndex, priceTypeColumns, location.pathname]);

	const { visibilityModel, setVisibilityModel, visibilityModelSaveConfigKey } = useAdjustableColumns(initialColumns, {
		saveConfigKey: adjustSaveKey,
		initial: {
			lastPrice: false,
			enterPrice: false,
			minimalPrice: false,
			wholesalePrice: false,
		},
	});
	const { pinningModel, setPinningModel } = usePinnedColumns({ saveConfigKey: adjustSaveKey });

	return { columns: initialColumns, visibilityModel, setVisibilityModel, pinningModel, setPinningModel, visibilityModelSaveConfigKey };
};
