import type { Whoami } from 'models/auth';
import type { Client } from 'models/client';
import type { OrganizationOption } from 'models/employee';
import { CatalogueProductWithAmount } from 'models/product/catalogue-product';
import { roundNumber, toFixed } from 'utils/shared';

import type { DetailedTransferCandidate, ProductInternalModelState, ServiceInternalModelState, SuborderSnapshot } from '../OrderController';
import { SuborderTab, useTypedOrderControllerFromContext } from '../OrderController';
import { calculateTotals, calculateTotalServicesCost, getEntityDefaultPrice } from '../OrderController/lib/utils';
import { isString } from './../../../utils/type-guards';

export type EntityName = 'products' | 'services';
export interface OnEntityDeletePayload {
	entityName: EntityName;
	suborderIndex: number;
	candidates: string[];
}
export type OnEntityTransferPayload =
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: string[];
	  }
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: ProductInternalModelState[];
	  }
	| {
			entityName: EntityName;
			to: number;
			from: number;
			candidates: ServiceInternalModelState[];
	  };
export interface GetNewSuborderTabDataPayload {
	entityName: EntityName;
	data?: string[];
	from: number;
}
export interface AddClientToNewOrderPayload {
	client: Client;
	fallbackManager: Whoami;
	organization: OrganizationOption;
}
export interface AddEntityToOrderPayload {
	entityName: EntityName;
	to: number;
	candidates: CatalogueProductWithAmount[] | ServiceInternalModelState[];
	triggerRerender?: boolean;
}
export interface UpdateStatsPayload {
	to: number;
	entity: SuborderSnapshot;
	triggerRerender?: boolean;
}
export interface MarkAsSavedOnServerPayload {
	suborderIndexes: number[];
}

export const useOrderOperationMethods = () => {
	const { getValues, setValue, control, trigger, watch, handleSubmit, formState } = useTypedOrderControllerFromContext();

	const onEntityDelete = ({ entityName, suborderIndex, candidates }: OnEntityDeletePayload) => {
		const filterEntities = (source: AnyArg, entities: string[]) => {
			const newSource = { ...source };
			entities.forEach((id) => delete newSource[id]);
			return newSource;
		};

		const source = getValues(`suborders.${suborderIndex}.data.${entityName}`);
		const entitiesNeedKeep = filterEntities(source, candidates);

		if (suborderIndex > 0) {
			const draftOrder = getValues(`suborders.${0}.data.${entityName}`);
			const draftOrderEntitiesNeedKeep = filterEntities(draftOrder, candidates);

			setValue(`suborders.${0}.data.${entityName}`, draftOrderEntitiesNeedKeep);
		}

		setValue(`suborders.${suborderIndex}.data.${entityName}`, entitiesNeedKeep);
	};

	const getNewTabDataPayload = (payload: GetNewSuborderTabDataPayload): SuborderTab => {
		const { entityName, data, from = 0 } = payload ?? {};
		const repository = getValues('suborders');
		const source = repository[from];

		const entitiesRepository = Object.values(source.data[entityName] ?? {});
		const entityCandidates = entitiesRepository?.filter((entity) => data?.includes(entity.id)) ?? [];
		const services = entityName === 'services' ? Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])) : {};
		const products = entityName === 'products' ? Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])) : {};

		const index = repository.length;
		const tabName = `Заявка ${index}`;
		const rootOrderId = repository?.[0].data?.id ?? '';

		let stats = { weight: 0, sum: 0, volume: 0 };

		if (entityName === 'products') {
			stats = calculateTotals(entityCandidates);
		} else {
			stats.sum = calculateTotalServicesCost(entityCandidates as ServiceInternalModelState[]);
		}

		const tabData = {
			...source.data,
			sum: stats.sum,
			weight: stats.weight,
			volume: stats.volume,
			products,
			services,
			isSaved: false,
			isReserved: false,
			isPaid: false,
			isWithoutPayment: false,
			id: '',
			parentId: rootOrderId || null,
			[entityName]: Object.fromEntries(entityCandidates.map((entity) => [entity.id, entity])),
		} as SuborderSnapshot;

		return {
			tabName,
			index,
			data: tabData,
		} as SuborderTab;
	};

	const onEntityTransfer = ({ entityName, from, to, candidates }: OnEntityTransferPayload) => {
		const repository = getValues(`suborders.${from}.data.${entityName}`);

		if (from === 0) {
			const transferEntities = candidates
				.map((candidate) => repository[typeof candidate === 'string' ? candidate : candidate.id])
				.filter(Boolean);
			const allTransferEntities = [...Object.values(getValues(`suborders.${to}.data.${entityName}`)), ...transferEntities];

			setValue(`suborders.${to}.data.${entityName}`, Object.fromEntries(allTransferEntities.map((entity) => [entity.id, entity])));
		} else {
			const entityCandidates = candidates as DetailedTransferCandidate[];
			const candidatesMap = new Map<string, DetailedTransferCandidate>(
				entityCandidates.map((candidate) => [typeof candidate === 'string' ? candidate : candidate.id, candidate]),
			);

			let targetSuborder = getValues('suborders')?.[to];

			if (!targetSuborder) {
				targetSuborder = getNewTabDataPayload({ entityName, from });
			}

			const sourceSuborderEntities = getValues(`suborders.${from}.data.${entityName}`);
			const targetSuborderEntities = targetSuborder.data[entityName] ?? {};

			const patchedSourceSuborderEntities = Object.fromEntries(
				Object.entries<DetailedTransferCandidate>(sourceSuborderEntities)
					.map(([id, entity]) => {
						const candidate = candidatesMap.get(id);
						if (!candidate) return [id, entity];

						const delta = Number(entity.amount) - Number(candidate.amount);
						const totalPrice = String(Number(delta) * Number(entity.price));

						return [id, { ...entity, amount: delta, sum: totalPrice }];
					})
					.filter(([, entity]) => Number(isString(entity) ? entity : entity.amount) > 0),
			);

			const patchedTargetSuborderEntities = Object.fromEntries(
				Object.entries<DetailedTransferCandidate>(targetSuborderEntities)
					.map(([id, entity]) => {
						const candidate = candidatesMap.get(id);

						if (candidate) {
							const newAmount = toFixed(Number(candidate.amount) + (entity.amount ? Number(entity.amount) : 0));
							const totalPrice = toFixed(Number(newAmount) * Number(entity.price));
							return [id, { ...entity, amount: newAmount, sum: totalPrice }];
						}

						return [id, entity];
					})
					.concat(
						entityCandidates
							.filter((candidate) => !targetSuborderEntities[candidate.id])
							.map((candidate) => {
								const totalPrice = toFixed(Number(candidate.amount) * Number(candidate.price));
								return [candidate.id, { ...candidate, sum: totalPrice }];
							}),
					),
			);

			const updatedRootEntities = Object.entries<DetailedTransferCandidate>({
				...patchedSourceSuborderEntities,
				...patchedTargetSuborderEntities,
			}).reduce((acc, [id, entity]) => {
				const sourceEntity = patchedSourceSuborderEntities[id];
				const targetEntity = patchedTargetSuborderEntities[id];

				if (sourceEntity && targetEntity) {
					const totalAmount = toFixed(Number(sourceEntity.amount) + Number(targetEntity.amount));
					const totalPrice = Number(totalAmount) * Number(entity.price);

					acc[id] = {
						...entity,
						amount: totalAmount,
						sum: toFixed(totalPrice),
					};
				} else {
					acc[id] = entity;
				}

				return acc;
			}, {} as Record<string, DetailedTransferCandidate>);

			setValue(`suborders.${from}.data.${entityName}`, patchedSourceSuborderEntities);
			setValue(`suborders.${to}.data.${entityName}`, patchedTargetSuborderEntities);
			setValue(`suborders.0.data.${entityName}`, { ...getValues(`suborders.${0}.data.${entityName}`), ...updatedRootEntities });
		}
	};

	const addClientToNewOrder = ({ client, fallbackManager, organization }: AddClientToNewOrderPayload) => {
		const repository = getValues(`suborders.${0}.data`);
		const responsible = fallbackManager
			? {
					label: fallbackManager?.name ?? '',
					value: fallbackManager?.['1c_uuid'] ?? '',
			  }
			: null;

		const stock = fallbackManager?.stock
			? {
					label: fallbackManager.stock?.title ?? '',
					value: fallbackManager.stock?.id ?? '',
			  }
			: null;
		const contract = client.contracts?.[0]
			? {
					label: client.contracts?.[0]?.title,
					value: client.contracts?.[0]?.id,
			  }
			: null;

		const clientData = client
			? {
					label: client.name,
					value: client.id,
			  }
			: null;

		const newRepository = {
			...repository,
			responsible,
			organization: organization || null,
			client: clientData,
			stock,
			contract,
		};

		setValue(`suborders.${0}.data`, newRepository);
	};

	const addEntityToOrder = ({ candidates, entityName, to }: AddEntityToOrderPayload) => {
		const suborders = getValues('suborders');
		const rootSuborder = suborders[0];

		// Helper function to map candidates to entities with their IDs as keys
		const mapCandidatesToEntityIds = (entities: AnyArg[]) => entities.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {});

		if (entityName === 'services') {
			const servicesCandidates = candidates as ServiceInternalModelState[];
			const servicesInTargetSuborder = suborders[to].data.services;
			const mappedServicesCandidates = mapCandidatesToEntityIds(servicesCandidates);

			const rootServices = { ...rootSuborder.data.services, ...mappedServicesCandidates };
			const rootSum = rootSuborder.data.sum;
			const servicesCost = calculateTotalServicesCost(Object.values(rootServices));

			setValue(`suborders.${0}.data.services`, rootServices);
			setValue(`suborders.${0}.data.sum`, rootSum + servicesCost);

			const updatedSuborderServices = { ...servicesInTargetSuborder, ...mappedServicesCandidates };
			const suborderSum = suborders[to].data.sum;
			const suborderServicesCost = calculateTotalServicesCost(Object.values(updatedSuborderServices));

			setValue(`suborders.${to}.data.services`, updatedSuborderServices);
			setValue(`suborders.${to}.data.sum`, suborderSum + suborderServicesCost);
			return;
		}

		const productsInTargetSuborder = suborders[to].data.products;
		const productsCandidates = candidates.map((entity) => {
			const duplicate = productsInTargetSuborder[entity.id];

			if (duplicate) {
				const newAmount = Number(duplicate.amount) + Number(entity?.amount || 1);
				const newSum = roundNumber(Number(duplicate.price) * newAmount, 2);

				return {
					...duplicate,
					sum: toFixed(newSum),
					amount: toFixed(newAmount),
				};
			}

			const entityWithDefaultPrice = getEntityDefaultPrice(entity, 'products');
			const priceValue = toFixed(entityWithDefaultPrice?.price);
			const sum = toFixed(Number(entity.amount) * Number(priceValue));

			return {
				...entity,
				sum,
				price: priceValue,
			};
		}) as CatalogueProductWithAmount[];

		const mappedProductsCandidates = mapCandidatesToEntityIds(productsCandidates);

		const rootProducts = { ...rootSuborder.data.products, ...mappedProductsCandidates };
		const rootTotals = calculateTotals(Object.values(rootProducts));
		const totalServicesCost = calculateTotalServicesCost(Object.values(rootSuborder.data.services ?? {}));

		setValue(`suborders.${0}.data.products`, rootProducts);
		setValue(`suborders.${0}.data.sum`, rootTotals.sum + totalServicesCost);
		setValue(`suborders.${0}.data.weight`, rootTotals.weight);
		setValue(`suborders.${0}.data.volume`, rootTotals.volume);

		if (to !== 0) {
			const updatedSuborderProducts = { ...productsInTargetSuborder, ...mappedProductsCandidates };
			const suborderTotals = calculateTotals(Object.values(updatedSuborderProducts));
			const servicesInTargetSuborder = suborders[to].data.services;
			const totalSuborderServicesCost = calculateTotalServicesCost(Object.values(servicesInTargetSuborder ?? {}));

			setValue(`suborders.${to}.data.products`, updatedSuborderProducts);
			setValue(`suborders.${to}.data.sum`, suborderTotals.sum + totalSuborderServicesCost);
			setValue(`suborders.${to}.data.weight`, suborderTotals.weight);
			setValue(`suborders.${to}.data.volume`, suborderTotals.volume);
		}
	};

	const takeRollbackSnapshot = () => {
		const doesRollbackExist = getValues('rollbackSnapshot');

		if (doesRollbackExist) return;

		const suborders = getValues('suborders');
		setValue('rollbackSnapshot', structuredClone(suborders));
	};
	const rollbackState = () => {
		const rollbackSnapshot = getValues('rollbackSnapshot');

		if (!rollbackSnapshot) return;

		setValue('suborders', rollbackSnapshot);
	};
	const resetRollbackSnapshot = () => {
		setValue('rollbackSnapshot', null);
	};
	const getRollbackSnapshot = () => getValues('rollbackSnapshot');

	return {
		onEntityDelete,
		onEntityTransfer,
		getNewTabDataPayload,
		addClientToNewOrder,
		addEntityToOrder,
		control,
		getValues,
		setValue,
		trigger,
		watch,
		handleSubmit,
		formState,
		takeRollbackSnapshot,
		resetRollbackSnapshot,
		getRollbackSnapshot,
		rollbackState,
	};
};
