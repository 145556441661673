import axios from 'axios';

const apiClient = axios.create({
	baseURL: 'https://testback-unit.alterego.biz.ua/api/v1',
	headers: {
		'Content-Type': 'application/json',
	},
});

export default apiClient;
