// /src/components/UserPermissionsLoader.tsx
import Spinner from 'components/Spinner';
import { AbilityProvider } from 'contexts/AbilityContext';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const UserPermissionsLoader = () => {
	const auth = useAuth();

	useEffect(() => {
		auth.whoami();
	}, []);

	if (auth.isLoading) {
		return <Spinner />;
	}

	return (
		<AbilityProvider>
			<Outlet />
		</AbilityProvider>
	);
};

export default UserPermissionsLoader;
